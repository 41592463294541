
import { Component, Vue } from "vue-property-decorator";
import { ROOT_ACTIONS } from "@/store/actions";
import { ActivityType, ProgramFormula, Phase, Program, ProgramType } from "@/types";

import { firestore } from "@/firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";

@Component({
  components: {
    ProgramTypeSpan: () => import('@/components/typography/ProgramTypeSpan.vue')
  }
})
export default class Templates extends Vue {
  PHASE_COUNT = 3;

  // TODO: move this globally
  activityTypes = [
    {
      text: 'Individuele begeleiding',
      value: ActivityType.INDIVIDUAL_COACHING,
    },
    {
      text: 'Assesment',
      value: ActivityType.ASSESMENT
    },
    {
      text: 'Begeleiding',
      value: ActivityType.CLASSIC_TRAINING
    },
    {
      text: 'Webinar',
      type: ActivityType.WEBINAR,
    },
    {
      text: 'Workshop',
      type: ActivityType.WORKSHOP
    }
  ]


  templates: Program[] = [];

  headers = [
    { text: "Titel", value: "title" },
    { text: "Type", value: "type" },
  ];

  loading = {
    templates: false,
  }

  async mounted() {
    this.loading.templates = true;

    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Presence', target: '/' }, { text: 'Templates', target: '/admin/presence/templates' }])
    try {
      const templatesQuery = query(collection(firestore, 'program-templates'), orderBy('title'));

      const fetchedTemplates = await getDocs(templatesQuery);
      this.templates = fetchedTemplates.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        } as Program
      });
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading.templates = false;
    }
  }

  languages = [
    {
      text: 'Nederlands',
      value: 'nl-be'
    },
    {
      text: 'Frans',
      value: 'fr'
    }
  ]

}
